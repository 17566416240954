import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import EmailVerification from './steps/EmailVerification'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ProfileUpdater from './steps/ProfileUpdater'
import Progress from '../loaders/Progress'
import { setToast } from '../../store/reducers/toastReducer'
import {
  ADDRESS_ADDITION_SUCCESS_MESSAGE,
  ADDRESS_UPDATE_SUCCESS_MESSAGE,
  COMPANY_ASSIGNMENT_SUCCESS_MESSAGE,
  COMPANY_CREATION_SUCCESS_MESSAGE,
  EMAIL_VERIFICATION_CONFIRMATION,
  USER_UPDATE_SUCCESS_MESSAGE,
  VERIFICATION_CODE_CONFIRMATION
} from '../../constants/messages'
import { logoutCurrentUser, setCurrentUser } from '../../store/reducers/api/authReducer'
import { resetUserError, resetUserMessage } from '../../store/reducers/api/usersReducer'
import { dismissModal } from '../../utils/dismissModal'
import { AddressMode, CompanyMode, Mode, Title } from '../../types'
import AssignOrCreateCompany from './AssignOrCreateCompany'
import BillingOrDeliveryAddressCreation from './BillingOrDeliveryAddressCreation'
import * as userRoles from '../../constants/userRoles'
import { resetCompanyError, resetCompanyMessage } from '../../store/reducers/api/companyReducer'
import { getMyProfile } from '../../store/reducers/api/profileReducer'
import { calculateProgress, companyAddressProgress } from '../../utils/calculateOnboardingProgress'
import { setOnboardingProgress } from '../../store/reducers/onboardingReducer'
import LogInToAccount from './steps/LogInToAccount'

const LandingModal = ({ onboardingStartCount }: { onboardingStartCount: number }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.profile.isLoading)
  const isUpdating = useAppSelector((state) => state.apiUsers.isLoading)
  const userMessage = useAppSelector((state) => state.apiUsers.message)
  const userError = useAppSelector((state) => state.apiUsers.error)
  const updatedUser = useAppSelector((state) => state.apiUsers.user)
  const updatedAddress = useAppSelector((state) => state.apiUsers.address)
  const companyError = useAppSelector((state) => state.apiCompany.error)
  const companyMessage = useAppSelector((state) => state.apiCompany.message)
  const isLoadingCompany = useAppSelector((state) => state.apiCompany.isLoading)
  const onboardingProgress = useAppSelector((state) => state.onboarding.onboardingProgress)

  const [mode, setMode] = useState<Mode>('logInToAccount')
  const [companyMode, setCompanyMode] = useState<CompanyMode>('companyAssignment')
  const [addressMode, setAddressMode] = useState<AddressMode>('billingAddress')
  const [title, setTitle] = useState<Title>('Account Login')
  const [editBillingOrDeliveryAddress, setEditBillingOrDeliveryAddress] = useState(localStorage.getItem('editBillingOrDeliveryAddress') === 'yes')
  const [isBillingAndDeliveryAddress, setIsBillingAndDeliveryAddress] = useState(localStorage.getItem('isBillingAndDeliveryAddress') === 'yes')

  const dispatch = useAppDispatch()

  const token = currentUser?.token || null
  const email = profile?.email || ''
  const userId = profile?.id || null
  const role = profile?.role || userRoles.USER
  const isVerified = Boolean(profile?.isVerified)
  const company = profile?.company
  const firstName = profile?.firstName
  const addresses = profile?.addresses || []

  const renderMode = (mode: Mode) => {
    const selectedMode = {
      logInToAccount: <LogInToAccount setMode={setMode} setTitle={setTitle} />,
      verifyEmail: <EmailVerification isLoading={isLoading || isVerified} email={email} userId={userId} token={token} setMode={setMode} setTitle={setTitle} isVerified={isVerified} />,
      assignOrCreateCompany: <AssignOrCreateCompany setMode={setMode} setTitle={setTitle} userId={userId} role={role} token={token} profile={profile} companyMode={companyMode} setCompanyMode={setCompanyMode} setEditBillingOrDeliveryAddress={setEditBillingOrDeliveryAddress} editBillingOrDeliveryAddress={editBillingOrDeliveryAddress} />,
      updateProfile: <ProfileUpdater token={token} profile={profile} isLoading={isLoading || isUpdating} setMode={setMode} setTitle={setTitle} editBillingOrDeliveryAddress={editBillingOrDeliveryAddress} />,
      billingOrDeliveryAddress: <BillingOrDeliveryAddressCreation setMode={setMode} setTitle={setTitle} company={profile?.company} token={token} addressMode={addressMode} setAddressMode={setAddressMode} isBillingAndDeliveryAddress={isBillingAndDeliveryAddress} setIsBillingAndDeliveryAddress={setIsBillingAndDeliveryAddress} setCompanyMode={setCompanyMode} />
    }

    return selectedMode[mode]
  }

  useEffect(() => {
    if (userError && userError.errors) {
      const payload = {
        title: 'Error',
        message: `Kindly try again, ${userError.errors.message}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetUserError())
    }
  }, [userError])

  useEffect(() => {
    if (companyError && companyError.errors) {
      const payload = {
        title: 'Error',
        message: `${companyError.errors.message}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCompanyError())
    }
  }, [companyError])

  useEffect(() => {
    if (userMessage && (userMessage === EMAIL_VERIFICATION_CONFIRMATION || userMessage === COMPANY_ASSIGNMENT_SUCCESS_MESSAGE)) {
      dismissModal('landingModal')
      const payload = {
        title: 'Success',
        message: `${userMessage}. You will be redirected to the log in page.`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      const timer = setTimeout(() => {
        dispatch(logoutCurrentUser())
      }, 5000)

      return () => {
        clearTimeout(timer)
      }
    }

    if (userMessage && userMessage === USER_UPDATE_SUCCESS_MESSAGE) {
      dismissModal('landingModal')
      const payload = {
        title: 'Success',
        message: userMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetUserMessage())
      const controller = new AbortController()
      const signal = controller.signal

      if (updatedUser && userId === updatedUser.id) {
        const updatedAddresses = addresses.length === 0
          ? [updatedAddress]
          : addresses.map(address =>
            address.id === updatedAddress?.id ? updatedAddress : address
          )
        dispatch(setCurrentUser({ ...updatedUser, addresses: updatedAddresses, token }))
      }

      if (token) {
        dispatch(getMyProfile({ token, signal }))
      }
    }

    if (userMessage && userMessage === VERIFICATION_CODE_CONFIRMATION) {
      const payload = {
        title: 'OTP request successful',
        message: userMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
    }
  }, [userMessage, updatedUser])

  useEffect(() => {
    if (companyMessage && (companyMessage === COMPANY_CREATION_SUCCESS_MESSAGE)) {
      dismissModal('landingModal')
      const payload = {
        title: 'Success',
        message: `${companyMessage}. You will be redirected to the log in page.`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      const timer = setTimeout(() => {
        dispatch(logoutCurrentUser())
      }, 5000)

      return () => {
        clearTimeout(timer)
      }
    }

    if (companyMessage && (companyMessage === ADDRESS_ADDITION_SUCCESS_MESSAGE ||
      companyMessage === ADDRESS_UPDATE_SUCCESS_MESSAGE)) {
      const payload = {
        title: 'Success',
        message: `${companyMessage}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }

      dispatch(setToast(payload))
      dispatch(resetCompanyMessage())
      const controller = new AbortController()
      const signal = controller.signal

      if (token) {
        dispatch(getMyProfile({ token, signal }))
      }
    }
  }, [companyMessage])

  useEffect(() => {
    if (profile) {
      const { totalPendingTasks, totalProgressPercentage } = calculateProgress(profile, editBillingOrDeliveryAddress, isBillingAndDeliveryAddress)
      const payload = {
        onboardingProgress: totalProgressPercentage,
        totalPendingTasks
      }
      dispatch(setOnboardingProgress(payload))
    }
  }, [profile, editBillingOrDeliveryAddress, isBillingAndDeliveryAddress])

  useEffect(() => {
    switch (true) {
      case !isVerified:
        setMode('verifyEmail')
        setTitle('Email Verification')
        break
      case !company && role !== userRoles.ADMIN:
        setMode('assignOrCreateCompany')
        break
      case editBillingOrDeliveryAddress && companyAddressProgress(company, isBillingAndDeliveryAddress) !== 25:
        setMode('billingOrDeliveryAddress')
        setTitle('Address Information')
        break
      case !firstName || addresses.length === 0:
        setMode('updateProfile')
        setTitle('Profile Information')
        break
    }
  }, [isVerified, company, firstName, addresses, onboardingStartCount])

  return (
    <div className="modal fade" id="landingModal" tabIndex={-1} aria-labelledby="landingModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div>
              <div className="row g-0">
                <div className="col-md-4 bg-secondary-subtle px-2">
                  <div className="modal-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <div className="progress my-4" role="progressbar" aria-label="Onboarding Progress" aria-valuenow={onboardingProgress} aria-valuemin={0} aria-valuemax={100}>
                          <div className={`progress-bar w-${onboardingProgress}`}></div>
                        </div>
                      </div>
                      <div className="ms-2">{`${onboardingProgress}%`}</div>
                    </div>
                    <ol className="list-group list-group-numbered">
                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'logInToAccount' && 'active'}`}
                        onClick={() => {
                          setMode('logInToAccount')
                          setTitle('Account Login')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Log in to your account
                        </div>
                        <i className="bi bi-check2-circle"></i>
                      </button>
                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'verifyEmail' && 'active'}`}
                        onClick={() => {
                          setMode('verifyEmail')
                          setTitle('Email Verification')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Verify your email address
                        </div>
                        {isVerified ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>
                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'assignOrCreateCompany' && 'active'}`}
                        onClick={() => {
                          setMode('assignOrCreateCompany')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          {companyMode === 'companyAssignment' ? 'Assign to company' : `${company ? 'View' : 'Create'} Company`}
                        </div>
                        {(company || role === userRoles.ADMIN) ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>
                      {editBillingOrDeliveryAddress && <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'billingOrDeliveryAddress' && 'active'}`}
                        onClick={() => {
                          setMode('billingOrDeliveryAddress')
                          setTitle('Address Information')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Add Address Information
                        </div>
                        {companyAddressProgress(company, isBillingAndDeliveryAddress) === 25 ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>}
                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'updateProfile' && 'active'}`}
                        onClick={() => {
                          setMode('updateProfile')
                          setTitle('Profile Information')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Add profile information
                        </div>
                        {(firstName && addresses.length > 0) ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>
                    </ol>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="modal-header border-0">
                    <h1 className="modal-title fs-5" id="landingModalLabel">{title}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  {(isLoading || isUpdating || isLoadingCompany) ? <Progress marginBottom={false} /> : <hr className="border border-primary border-1 opacity-50 my-0"></hr>}
                  {renderMode(mode)}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default LandingModal
