import React, { useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import {
  resetShoppingCartBundles,
  resetShoppingCartLeftOverProducts,
  setIsBulkOrder,
  setShoppingAddresses,
  setShoppingCartBundles,
  setShoppingCartLeftOverProducts
} from '../../../store/reducers/shoppingCartReducer'
import BundleConfiguratorItem from './BundleConfiguratorItem'
import { formatPrice } from '../../../utils/formatPrice'
import { calculateGraduatedPrice } from '../../../utils/calculateGraduatedPrice'
import Placeholder from '../../../assets/images/placeholder.png'
import { ShoppingCartProduct } from '../../../types'
const BundleConfigurator = () => {
  const dispatch = useAppDispatch()
  const shoppingCartProducts = useAppSelector(
    (state) => state.shoppingCart.products
  )
  const shoppingCartBundles = useAppSelector(
    (state) => state.shoppingCart.bundles
  )
  const shoppingCartLeftOverProducts = useAppSelector(
    (state) => state.shoppingCart.leftOverProducts
  )
  const shoppingCartAddresses = useAppSelector(
    (state) => state.shoppingCart.addresses
  )
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)
  const preMadeBox = useAppSelector(
    (state) => state.preMadeBoxReducer.preMadeBox
  )

  useEffect(() => {
    const updatedBundles = shoppingCartBundles.map((bundle, index) => {
      const minStockLevel = bundle.products.reduce(
        (min, product) => Math.min(product?.stock?.stockLevel ?? min, min),
        Infinity
      )
      const minStockLevelReserved = bundle.products.reduce(
        (min, product) =>
          Math.min(product?.stock?.stockLevelReserved ?? min, min),
        Infinity
      )

      return {
        ...bundle,
        name: `Bundle ${index + 1}`,
        stock: {
          stockLevel: minStockLevel,
          stockLevelReserved: minStockLevelReserved
        }
      }
    })
    if (
      JSON.stringify(updatedBundles) !== JSON.stringify(shoppingCartBundles)
    ) {
      dispatch(setShoppingCartBundles({ bundles: updatedBundles }))
    }
  }, [shoppingCartBundles, dispatch])

  const handleCreateBundle = () => {
    const flags = {
      boxes: false,
      fillingMaterials: false
    }

    if (shoppingCartProducts.length > 0) {
      const payload = {
        bundles: [
          {
            id: uuidv1(),
            products: shoppingCartProducts
              .filter((product) => {
                if (
                  product.productTags.some(
                    (tag) =>
                      tag.productCategoryTag.name.toLowerCase() === 'boxes'
                  ) &&
                  !preMadeBox.some(
                    (preMadeProduct) => preMadeProduct === product.id
                  )
                ) {
                  if (flags.boxes) {
                    return false
                  }
                  flags.boxes = true
                }
                if (
                  product.productTags.some(
                    (tag) =>
                      tag.productCategoryTag.name.toLowerCase() ===
                      'filling materials'
                  ) &&
                  !preMadeBox.some(
                    (preMadeProduct) => preMadeProduct === product.id
                  )
                ) {
                  if (flags.fillingMaterials) {
                    return false
                  }
                  flags.fillingMaterials = true
                }

                return !preMadeBox.some(
                  (preMadeProduct) => preMadeProduct === product.id
                )
              })
              .map((product) => ({ ...product, quantity: 1 })),
            quantity: 1
          }
        ]
      }
      let minStockLevel = Infinity
      let minStockLevelReserved = Infinity
      payload.bundles[0].products.forEach((product) => {
        minStockLevel = Math.min(
          minStockLevel,
          product.stock?.stockLevel ?? Infinity
        )
        minStockLevelReserved = Math.min(
          minStockLevelReserved,
          product.stock?.stockLevelReserved ?? Infinity
        )
      })
      const updatedPayload = {
        ...payload,
        bundles: payload.bundles.map((bundle) => ({
          ...bundle,
          stock: {
            stockLevel: minStockLevel,
            stockLevelReserved: minStockLevelReserved
          }
        }))
      }
      dispatch(setShoppingCartBundles(updatedPayload))
    }
  }

  const handleAddAnotherBundle = () => {
    if (shoppingCartProducts.length > 0) {
      const newBundle = {
        id: uuidv1(),
        products: [
          ...shoppingCartLeftOverProducts.map((product) => ({
            ...product,
            quantity: 1
          }))
        ],
        quantity: 1
      }
      const updatedBundles = [...shoppingCartBundles, newBundle]
      dispatch(setShoppingCartBundles({ bundles: updatedBundles }))
    }
  }

  const getLeftOverProducts = () => {
    const totalBundleProducts = shoppingCartBundles
      .flatMap((shoppingCartBundle) => {
        return shoppingCartBundle.products.map((product) => ({
          ...product,
          quantity: product.quantity * shoppingCartBundle.quantity
        }))
      })
      .reduce((acc: ShoppingCartProduct[], product) => {
        const existingProduct = acc.find(
          (p: ShoppingCartProduct) => p.id === product.id
        )
        if (existingProduct) {
          existingProduct.quantity += product.quantity
        } else {
          acc.push(product)
        }
        return acc
      }, [])

    const leftOverProducts = shoppingCartProducts
      .filter((shoppingCartProduct) => {
        const foundBundleProduct = totalBundleProducts.find(
          (totalBundleProduct) =>
            totalBundleProduct.id === shoppingCartProduct.id
        )
        return (
          !foundBundleProduct
        )
      })
      .map((filteredShoppingCartProduct) => {
        const foundBundleProduct = totalBundleProducts.find(
          (totalBundleProduct) =>
            totalBundleProduct.id === filteredShoppingCartProduct.id
        )

        return {
          ...filteredShoppingCartProduct,
          quantity: foundBundleProduct
            ? filteredShoppingCartProduct.quantity - foundBundleProduct.quantity
            : filteredShoppingCartProduct.quantity
        }
      })

    const payload = {
      leftOverProducts
    }
    dispatch(setShoppingCartLeftOverProducts(payload))
  }

  const removeLeftOverProductIdsAndBundleIdsFromShoppingCartAddresses = () => {
    const updatedShippingAddresses = shoppingCartAddresses.map((address) => ({
      ...address,
      assignedLeftOverProductIds: [],
      assignedBundleIds: []
    }))
    const payload = {
      addresses: updatedShippingAddresses
    }
    dispatch(setShoppingAddresses(payload))
  }

  useEffect(() => {
    if (shoppingCartBundles.length > 0) {
      getLeftOverProducts()
    }
  }, [shoppingCartBundles])

  useEffect(() => {
    if (shoppingCartBundles.length > 0) {
      dispatch(setIsBulkOrder(false))
    } else {
      dispatch(setIsBulkOrder(true))
    }
  }, [shoppingCartBundles.length])

  useEffect(() => {
    if (shoppingCartProducts.length === 0) {
      dispatch(resetShoppingCartBundles())
      removeLeftOverProductIdsAndBundleIdsFromShoppingCartAddresses()
    }
  }, [shoppingCartProducts.length])

  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <div className="border rounded p-2">
            <h6 className="fw-bold mb-2">
              Would you like to order in bulk or create a bundle?
            </h6>
            <p className="mb-3">
              You can select products and combine them into a customised bundle
            </p>
            <div className="d-flex gap-2">
              <button
                className={`btn ${
                  isBulkOrder ? 'btn-primary' : 'btn-outline-primary'
                }`}
                onClick={() => {
                  if (!isBulkOrder) {
                    dispatch(resetShoppingCartBundles())
                    dispatch(resetShoppingCartLeftOverProducts())
                    dispatch(setIsBulkOrder(true))
                  }
                }}
                disabled={shoppingCartProducts.length === 0 || isBulkOrder}
              >
                Order in Bulk
              </button>
              <button
                className={`btn ${
                  isBulkOrder ? 'btn-outline-primary' : 'btn-primary'
                }`}
                onClick={() => {
                  if (isBulkOrder) {
                    handleCreateBundle()
                    dispatch(setIsBulkOrder(false))
                  }
                }}
                disabled={
                  shoppingCartProducts.length === 0 ||
                  !isBulkOrder ||
                  preMadeBox.length >= shoppingCartProducts.length
                }
              >
                Create a Bundle
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="container">
          {shoppingCartBundles.map((shoppingCartBundle, index) => (
            <BundleConfiguratorItem
              key={`${
                shoppingCartBundle.spliteId ?? shoppingCartBundle.id
              }-bundle-configurator-item`}
              shoppingCartBundleId={
                shoppingCartBundle.spliteId ?? shoppingCartBundle.id
              }
              index={index}
            />
          ))}
        </div>
      </div>
      {shoppingCartLeftOverProducts.length > 0 && (
        <div className="row">
          <div className="col">
            <div className="card shadow-none">
              <div className="card-body">
                <h6 className="card-title">Leftover Products</h6>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                  {shoppingCartLeftOverProducts.map((product) => (
                    <div key={product.spliteId ?? product.id} className="col">
                      <div className="card h-100">
                        <img
                          src={
                            product.pictures && product.pictures.length > 0
                              ? product.pictures[0].publicUrl
                              : Placeholder
                          }
                          className="card-img-top"
                          alt={product.name}
                          style={{ objectFit: 'contain', maxHeight: '200px' }}
                        />
                        <div className="card-body p-2">
                          <small>{product.name}</small>
                        </div>
                        <div className="card-footer border-0 p-2">
                          <div className="d-flex justify-content-between">
                            <small className="fw-bold">
                              {formatPrice('EUR', navigator.language).format(
                                calculateGraduatedPrice(
                                  product.graduatedPrices,
                                  product.quantity,
                                  product.netRetailPrice.amount
                                ) * product.quantity
                              )}
                            </small>
                            <small>Quantity: {product.quantity}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {shoppingCartBundles.length > 0 &&
        shoppingCartLeftOverProducts.length > 0 && (
          <div className="row mt-4">
            <div className="col">
              <button
                className="btn btn-outline-primary"
                onClick={() => handleAddAnotherBundle()}
              >
                Add Another Bundle
              </button>
            </div>
          </div>
      )}
    </div>
  )
}

export default BundleConfigurator
