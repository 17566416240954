export const READ = 'read'
export const READWRITE = 'readwrite'
export const NOACCESS = 'noaccess'

export const permissionsObject = {
  [READ]: 'Read',
  [READWRITE]: 'Read Write',
  [NOACCESS]: 'No Access',
  '': ''
}
