import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ItemService from '../../../../services/api/wawiAPI/ItemService'
import { WawiApiItemState, WawiApiItem } from '../../../../types'
import { ARTICLE_ITEM_CREATION_SUCCESS_MESSAGE } from '../../../../constants/messages'

const initialState: WawiApiItemState = {
  isLoading: false,
  error: null,
  item: null,
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const createArticleItem = createAsyncThunk('api/items/create', async ({ token, item, signal }: { token: string, item: Partial<WawiApiItem>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ItemService.createArticleItem(token, item, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const itemSlice = createSlice({
  name: 'api/item',
  initialState,
  reducers: {
    resetItemError: (state) => {
      state.error = null
    },
    resetItemMessage: (state) => {
      state.message = null
    },
    resetItemData: (state) => {
      state.error = null
      state.item = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createArticleItem.pending, (state) => {
        state.item = null
        state.isLoading = true
      })
      .addCase(createArticleItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.item = action.payload.item
        state.error = null
        state.message = ARTICLE_ITEM_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createArticleItem.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetItemError, resetItemMessage, resetItemData } = itemSlice.actions

const { reducer } = itemSlice
export default reducer
