import { createSlice } from '@reduxjs/toolkit'
import { ShoppingCartState } from '../../types'
const initialState: ShoppingCartState = {
  products: [],
  addresses: [],
  bundles: [],
  leftOverProducts: [],
  bundleConfiguratorMode: null,
  isBulkOrder: true,
  splitProduct: null,
  assignedProducts: {}
}

const shoppingCartSlice = createSlice({
  name: 'shopping-cart',
  initialState,
  reducers: {
    setShoppingCart: (state, action) => {
      const { products } = action.payload
      state.products = products || []
    },
    removeFromShoppingCart: (state, action) => {
      const { product } = action.payload
      state.products = state.products.filter(item => item.id !== product.id) || []
    },
    resetShoppingCart: (state) => {
      state.products = []
    },
    setShoppingAddresses: (state, action) => {
      const { addresses } = action.payload
      state.addresses = addresses
    },
    resetShoppingAddresses: (state) => {
      state.addresses = []
    },
    setBundleConfiguratorMode: (state, action) => {
      const { bundleConfiguratorMode } = action.payload
      state.bundleConfiguratorMode = bundleConfiguratorMode
    },
    resetBundleConfiguratorMode: (state) => {
      state.bundleConfiguratorMode = null
    },
    setIsBulkOrder: (state, action) => {
      const isBulkOrder = action.payload
      state.isBulkOrder = isBulkOrder
    },
    setShoppingCartBundles: (state, action) => {
      const { bundles } = action.payload
      state.bundles = bundles
    },
    resetShoppingCartBundles: (state) => {
      state.bundles = []
    },
    setShoppingCartLeftOverProducts: (state, action) => {
      const { leftOverProducts } = action.payload
      state.leftOverProducts = leftOverProducts
    },
    resetShoppingCartLeftOverProducts: (state) => {
      state.leftOverProducts = []
    },
    setSplitProduct: (state, action) => {
      state.splitProduct = action.payload
    },
    clearSplitProduct: (state) => {
      state.splitProduct = null
    },
    setAssignedProducts: (state, action) => {
      const { assignedProducts } = action.payload
      state.assignedProducts = assignedProducts
    },
    incrementProductQuantity: (state, action) => {
      const { productId, addressId, isBundle } = action.payload
      const updatedAssignedProducts = { ...state.assignedProducts }
      const addressProducts = updatedAssignedProducts[addressId] || []
      const productIndex = addressProducts.findIndex(item => item.id === productId)
      if (productIndex !== -1) {
        if (isBundle ? (state.bundles.find(bundle => bundle.id === productId)?.quantity || 0) > 0 : (state.products.find(product => product.id === productId)?.quantity || 0) > 0) {
          addressProducts[productIndex].quantity += 1
          if (isBundle) {
            const bundleIndex = state.bundles.findIndex(bundle => bundle.id === productId)
            if (bundleIndex !== -1 && state.bundles[bundleIndex].quantity > 0) {
              state.bundles[bundleIndex].quantity -= 1
            }
          } else {
            const productIndexInState = state.products.findIndex(product => product.id === productId)
            const updatedLeftOverProductIndex = state.leftOverProducts.findIndex(product => product.id === productId)
            if (updatedLeftOverProductIndex !== -1 && state.leftOverProducts[updatedLeftOverProductIndex].quantity > 0) {
              state.leftOverProducts[updatedLeftOverProductIndex].quantity -= 1
            }
            if (updatedLeftOverProductIndex === -1 && productIndexInState !== -1 && state.products[productIndexInState].leftOverQuantity > 0) {
              state.products[productIndexInState].leftOverQuantity -= 1
            }
          }
        }
      } else {
        addressProducts.push({ id: productId, quantity: 1, isBundle })
      }
      updatedAssignedProducts[addressId] = addressProducts
      state.assignedProducts = updatedAssignedProducts
    },
    decrementProductQuantity: (state, action) => {
      const { productId, addressId, isBundle } = action.payload
      const updatedAssignedProducts = { ...state.assignedProducts }
      const addressProducts = updatedAssignedProducts[addressId] || []
      const productIndex = addressProducts.findIndex(item => item.id === productId)
      if (productIndex !== -1) {
        if (addressProducts[productIndex].quantity > 0) {
          addressProducts[productIndex].quantity -= 1
          if (isBundle) {
            const bundleIndex = state.bundles.findIndex(bundle => bundle.id === productId)
            if (bundleIndex !== -1) {
              state.bundles[bundleIndex].quantity += 1
            }
          } else {
            const updatedLeftOverProductIndex = state.leftOverProducts.findIndex(product => product.id === productId)
            if (updatedLeftOverProductIndex !== -1) {
              state.leftOverProducts[updatedLeftOverProductIndex].quantity += 1
            }
            const productIndexInState = state.products.findIndex(product => product.id === productId)
            if (productIndexInState !== -1 && updatedLeftOverProductIndex === -1) {
              state.products[productIndexInState].leftOverQuantity += 1
            }
          }
          if (addressProducts[productIndex].quantity === 0) {
            addressProducts.splice(productIndex, 1)
          }
        }
      }
      updatedAssignedProducts[addressId] = addressProducts
      state.assignedProducts = updatedAssignedProducts
    }
  }
})

export const {
  setSplitProduct,
  clearSplitProduct,
  setShoppingCart,
  resetShoppingCart,
  setShoppingAddresses,
  resetShoppingAddresses,
  setBundleConfiguratorMode,
  resetBundleConfiguratorMode,
  setShoppingCartBundles,
  resetShoppingCartBundles,
  setShoppingCartLeftOverProducts,
  resetShoppingCartLeftOverProducts,
  setIsBulkOrder,
  removeFromShoppingCart,
  setAssignedProducts,
  incrementProductQuantity,
  decrementProductQuantity
} = shoppingCartSlice.actions

const { reducer } = shoppingCartSlice
export default reducer
