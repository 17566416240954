import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { getACatalogueProductById, getSimilarCatalogueProducts, resetProductError } from '../../../store/reducers/api/productReducer'
import Placeholder from '../../../assets/images/placeholder.png'
import { Module, Permission, Product, ShoppingCartAddress, ShoppingCartProduct } from '../../../types'
import { formatPrice } from '../../../utils/formatPrice'
import * as userRoles from '../../../constants/userRoles'
import SimilarProductLoader from '../../../components/Shop/SimilarProductLoader'
import { setShoppingCart } from '../../../store/reducers/shoppingCartReducer'
import { setToast } from '../../../store/reducers/toastReducer'
import ShoppingModals from '../../../components/Shop/ShoppingModals'
import { loadCostCenterOptions } from '../../../utils/loadCostCenterOptions'
import { debounce } from '../../../utils/debounce'
import hasPermission from '../../../utils/checkPermissions'
import { READ } from '../../../constants/permissions'
import * as appModules from '../../../constants/appModules'
import { Tooltip } from '../../../components/Tooltip'
import { getMinimumProductOrderQuantity } from '../../../utils/getMinimumProductOrderQuantity'
import { getMaximumProductOrderQuantity } from '../../../utils/getMaximumProductOrderQuantity'
import { getProductSizes } from '../../../store/reducers/api/productSizeReducer'
import ChildProductLoader from '../../../components/Shop/ChildProductLoader'
import { addToPreMadeBox } from '../../../store/reducers/PreMadeBoxReducer'

const ShopItemDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const product = useAppSelector((state) => state.apiProduct.catalogueProduct)
  const isLoadingProduct = useAppSelector((state) => state.apiProduct.isLoading)
  const error = useAppSelector((state) => state.apiProduct.error)
  const isLoadingSimilarProducts = useAppSelector((state) => state.apiProduct.isLoadingSimilarProducts)
  const similarProducts = useAppSelector((state) => state.apiProduct.similarProducts)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const productSizes = useAppSelector((state) => state.apiProductSize.productSizes)
  const isLoadingProductSizes = useAppSelector((state) => state.apiProductSize.isLoading)

  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [shopItemQuantity, setShopItemQuantity] = useState(1)
  const [selectedAddress, setSelectedAddress] = useState<Partial<ShoppingCartAddress> | null>(null)
  const [selectedMultipleAddresses, setSelectedMultipleAddresses] = useState<string[] | null>([])
  const [selectedChild, setSelectedChild] = useState<Product | null>(null)
  const [filterSize, setFilterSize] = useState('')

  const dispatch = useAppDispatch()
  const { productId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER
  const companyId = profile?.company?.id

  const productSizeVariations = selectedChild?.children.filter(child => (child.productSize?.name || '') === filterSize) ?? []
  const productSizeColorOptions = productSizeVariations.filter(child => child.productColor)

  const graduatedPrices = selectedChild?.graduatedPrices || []
  const graduatedPricesSorted = [...graduatedPrices].sort((a, b) => a.lastUnit - b.lastUnit)
  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || getMinimumProductOrderQuantity((shoppingCartProduct.minimumOrderQuantity ?? 1), shoppingCartProduct.graduatedPrices) }))
  const userId = profile?.id
  const companyOwnerId = profile?.company?.owner?.id
  const isOwner = companyOwnerId && userId === companyOwnerId

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []
  const maximumQuantity = 1000

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const loadCostCenterOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: { value: string, label: string }[]) => void) => {
      companyId &&
      loadCostCenterOptions(companyId, 1, 1000, String(token), inputValue, isAllowed(appModules.COSTCENTERS))
        .then(options => callback(options))
    }, 800),
    [companyId]
  )

  const addToCartButtonText = (shoppingCartProductsWithQuantity: ShoppingCartProduct[], product: Product | null) => {
    if (!product) {
      return 'Add to Cart'
    }
    const foundShoppingCartProduct = shoppingCartProductsWithQuantity.find((shoppingCartProduct) => shoppingCartProduct.id === product.id)

    if (foundShoppingCartProduct && foundShoppingCartProduct.quantity !== shopItemQuantity) {
      return 'Update Cart'
    }
    return 'Add to Cart'
  }

  const areAllProductSizesNull = (selectedChild: Product) => {
    return selectedChild.children.every(child => child.productSize === null)
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId) {
      dispatch(getACatalogueProductById({ token, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId])

  useEffect(() => {
    if (product) {
      setSelectedChild(product)
    }
  }, [product])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const perPage = 6
    const page = 1

    if (token && selectedChild && selectedChild.id) {
      dispatch(getSimilarCatalogueProducts({ token, productId: selectedChild.id, perPage, page, signal }))
    }
    setShopItemQuantity(getMinimumProductOrderQuantity((selectedChild?.minimumOrderQuantity ?? 1), graduatedPricesSorted))

    return () => {
      controller.abort()
    }
  }, [selectedChild])

  useEffect(() => {
    if (error && error.errors) {
      const payload = {
        title: 'Error',
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetProductError())
    }
  }, [error])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const page = 1
    const perPage = 50

    if (token && product && product.children.length > 0) {
      const productSizeType = product.children.find(child => child.productSize?.type != null)?.productSize?.type
      productSizeType && dispatch(getProductSizes({ token, perPage, page, signal, search: productSizeType }))
    }

    return () => {
      controller.abort()
    }
  }, [product])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card p-4">
          <div className="row">
            <div className="col-md-6">
              <button className="text-decoration-none btn btn-sm btn-outline-primary"
                onClick={() => {
                  if ((location.state as { from: string })?.from === 'shop') {
                    navigate(-1)
                  } else {
                    const state = location.state as { searchParams: string }
                    if (state && state.searchParams) {
                      navigate(`/shop?${state.searchParams}`)
                    } else {
                      navigate('/shop')
                    }
                  }
                }}
              >
                <i className="bi bi-arrow-left me-1"></i>Back to shop
              </button>
              <div className="row mb-4 mt-2">
                <div className="col-3">
                  {
                    isLoadingProduct
                      ? (
                          Array.from(Array(2).keys()).map((n: number) => (
                          <img
                            key={n}
                            src={Placeholder}
                            alt="Product thumbnail"
                            className="img-fluid rounded mb-2 border"
                            style={{ objectFit: 'cover', maxHeight: 180 }}
                            role="button"
                            tabIndex={0}
                          />
                          ))
                        )
                      : (
                          selectedChild?.pictures && selectedChild.pictures.length > 0
                            ? (
                                selectedChild.pictures.map((picture, index) => (
                                <img
                                  key={`product-${index}`}
                                  src={picture.publicUrl}
                                  alt="Product thumbnail"
                                  className="img-fluid rounded mb-2 border"
                                  style={{ objectFit: 'cover', maxHeight: 180 }}
                                  onClick={() => {
                                    setSelectedImageIndex(index)
                                  }}
                                  role="button"
                                  tabIndex={0}
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement
                                    target.onerror = null
                                    target.src = Placeholder
                                  }}
                                />
                                ))
                              )
                            : (
                              <img
                                src={Placeholder}
                                alt="Product thumbnail"
                                className="img-fluid rounded"
                                style={{ objectFit: 'cover', maxHeight: 180 }}
                              />
                              )
                        )
                  }
                </div>
                <div className="col-8 h-100">
                  {
                    isLoadingProduct
                      ? (
                        <div>
                          <img
                            src={Placeholder}
                            alt="Product Loader"
                            className="img-fluid rounded"
                            style={{ maxHeight: 300, objectFit: 'contain', width: '100%' }}
                          />
                        </div>
                        )
                      : (
                          selectedChild?.pictures && selectedChild.pictures.length > 1
                            ? (
                              <div id="productCarousel" className="carousel carousel-dark slide">
                                <div className="carousel-inner">
                                  {
                                    selectedChild.pictures.map((picture, index) => (
                                      <div key={`carousel-picture-${picture.number}-${index}`} className={`carousel-item ${index === selectedImageIndex ? 'active' : ''}`}>
                                        <img
                                          src={selectedChild?.pictures && selectedChild.pictures.length > 0 ? selectedChild.pictures[index].publicUrl : Placeholder}
                                          alt={`Product - ${selectedChild?.name}`}
                                          className="img-fluid rounded"
                                          style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }}
                                          onError={(e) => {
                                            const target = e.target as HTMLImageElement
                                            target.onerror = null
                                            target.src = Placeholder
                                          }}
                                        />
                                      </div>
                                    ))
                                  }
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel" data-bs-slide="prev">
                                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#productCarousel" data-bs-slide="next">
                                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                              )
                            : (
                              <img
                                src={selectedChild?.pictures && selectedChild.pictures.length > 0 ? selectedChild.pictures[selectedImageIndex].publicUrl : Placeholder}
                                alt={`Product - ${selectedChild?.name}`}
                                className="img-fluid rounded"
                                style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }}
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement
                                  target.onerror = null
                                  target.src = Placeholder
                                }}
                              />
                              )
                        )
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-7">
                  {
                    isLoadingProduct
                      ? (
                        <h5 className="placeholder-glow w-100">
                          <span className="placeholder col-6"></span>
                        </h5>
                        )
                      : (<h5>{selectedChild?.name}</h5>)
                  }
                </div>
                <div className="col-5 text-end">
                  <button
                    title="Open Cart"
                    type="button"
                    className="btn btn-outline-primary btn-sm position-relative text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#shoppingCartModal"
                  >
                    <i className="bi bi-cart me-1"></i>Cart
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {shoppingCartProductsWithQuantity.length}
                      <span className="visually-hidden">cart items</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="mt-4">
                {
                  isLoadingProduct
                    ? (
                      <p className="placeholder-glow">
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-4"></span>
                      </p>
                      )
                    : (<div className='product-description'>{selectedChild?.description ? parse(selectedChild?.description) : 'No description available'}</div>)
                }
              </div>
              <div className="my-4 d-flex justify-content-between">
                <div className="form-group">
                  <input
                    type="number"
                    min={getMinimumProductOrderQuantity((selectedChild?.minimumOrderQuantity ?? 1), graduatedPricesSorted)}
                    max={(selectedChild?.isExceedStockEnabled ? getMaximumProductOrderQuantity(maximumQuantity, graduatedPricesSorted) : Math.max((selectedChild?.stock?.stockLevel || 0) - (selectedChild?.stock?.stockLevelReserved || 0), 0) || 1)}
                    value={shopItemQuantity}
                    onChange={(e) => {
                      const max = Math.max((selectedChild?.stock?.stockLevel || 0) - (selectedChild?.stock?.stockLevelReserved || 0), 0) || 1
                      const min = getMinimumProductOrderQuantity((selectedChild?.minimumOrderQuantity ?? 1), graduatedPricesSorted)
                      const value = Number(e.target.value)
                      const quantity = selectedChild?.isExceedStockEnabled ? Math.max(Math.min(value, getMaximumProductOrderQuantity(maximumQuantity, graduatedPricesSorted)), min) : Math.max(Math.min(value, max), min)
                      setShopItemQuantity(quantity)
                    }}
                    name="quantity"
                    className="form-control"
                    aria-label={'Shop Item Quantity'}
                    autoComplete="off"
                    disabled={isLoadingProduct}
                  />
                </div>
                {
                  ((selectedChild && selectedChild.children.length > 0 && !isLoadingProduct) && !areAllProductSizesNull(selectedChild)) && (
                    <div className="mb-3 mx-2">
                      <select
                        aria-label="Choose a size"
                        onChange={(e) => {
                          setFilterSize(e.target.value)
                        }}
                        value={filterSize}
                        className="form-select"
                        id="sizes"
                        name="sizes"
                        disabled={isLoadingProductSizes}
                      >
                        <option value="">Select Size</option>
                        {
                          productSizes.map(productSize => (
                            <option key={productSize.id} value={productSize.name}>{(productSize.name).toUpperCase()}</option>
                          ))
                        }
                      </select>
                    </div>
                  )
                }
                <div className="">
                  <button
                    className="btn btn-outline-primary text-nowrap"
                    title="Add to Cart"
                    onClick={() => {
                      if (selectedChild && !selectedChild.isParent) {
                        const availableStock = Math.max((selectedChild.stock?.stockLevel || 0) - (selectedChild.stock?.stockLevelReserved || 0), 0)
                        if (selectedChild.isExceedStockEnabled || availableStock > 0) {
                          if (selectedChild.specifications && selectedChild.specifications.isBillOfMaterials) {
                            dispatch(addToPreMadeBox(selectedChild.id))
                          }
                          let message = `${selectedChild.name} added to your cart`
                          const foundProductInCartIndex = shoppingCartProductsWithQuantity.findIndex(shoppingCartProduct => shoppingCartProduct.id === selectedChild.id)
                          if (foundProductInCartIndex === -1) {
                            const updatedProducts = [...shoppingCartProductsWithQuantity, { ...selectedChild, quantity: shopItemQuantity, leftOverQuantity: shopItemQuantity }]
                            const payload = {
                              products: updatedProducts
                            }
                            dispatch(setShoppingCart(payload))
                          } else {
                            const updatedProductsInCart = [...shoppingCartProductsWithQuantity]
                            updatedProductsInCart[foundProductInCartIndex] = {
                              ...updatedProductsInCart[foundProductInCartIndex],
                              quantity: shopItemQuantity
                            }
                            const payload = {
                              products: updatedProductsInCart
                            }
                            dispatch(setShoppingCart(payload))

                            if (shopItemQuantity === shoppingCartProductsWithQuantity[foundProductInCartIndex].quantity) {
                              message = `${selectedChild.name} already in your cart`
                            } else {
                              message = `${selectedChild.name} quantity updated`
                            }
                          }
                          const toastPayload = {
                            title: 'Info',
                            message,
                            isVisible: true,
                            timestamp: dayjs().format('LT'),
                            type: 'info',
                            delay: 2000
                          }
                          dispatch(setToast(toastPayload))
                        } else {
                          const toastPayload = {
                            title: 'Out of Stock',
                            message: `There is no available stock for ${selectedChild.name}`,
                            isVisible: true,
                            timestamp: dayjs().format('LT'),
                            type: 'warning',
                            delay: 2000
                          }
                          dispatch(setToast(toastPayload))
                        }
                      } else {
                        const toastPayload = {
                          title: 'Select Variation',
                          message: 'Select a variation',
                          isVisible: true,
                          timestamp: dayjs().format('LT'),
                          type: 'warning',
                          delay: 2000
                        }
                        dispatch(setToast(toastPayload))
                      }
                    }}
                  >
                    <i className="bi bi-cart me-1"></i><span className="d-none d-md-inline-block">
                      {addToCartButtonText(shoppingCartProductsWithQuantity, selectedChild)}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4 border-top">
            <div className="col-md-6 col-sm-12">
              {
                isLoadingProduct
                  ? (
                    <p className="placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </p>
                    )
                  : (
                      productSizeVariations.length > 0
                        ? (
                            productSizeColorOptions.length > 0
                              ? (
                                <>
                                  <h6>Choose a color</h6>
                                  <div className="d-flex flex-row flex-wrap">
                                    {
                                      isLoadingProduct
                                        ? (
                                            Array.from(Array(3).keys()).map((n: number) => (
                                              <button
                                                className={'btn me-2 mb-2'}
                                                key={n}
                                                title={'Color Loader'}
                                                style={{
                                                  background: '#fff',
                                                  borderWidth: '1px',
                                                  borderColor: '#D3D4D4',
                                                  borderRadius: '50%',
                                                  width: '2.5rem',
                                                  height: '2.5rem '
                                                }}
                                                disabled
                                              />
                                            ))
                                          )
                                        : (
                                            productSizeColorOptions.map((productSizeColorOption, index) => (
                                              <Tooltip text={productSizeColorOption.productColor?.name || productSizeColorOption.name} placement="bottom" key={`color-${productSizeColorOption.id}-${index}`}>
                                                <button
                                                  className={`btn me-2 mb-2 ${selectedColor === productSizeColorOption.productColor?.hexCode && 'active'}`}
                                                  title={productSizeColorOption.productColor?.name || ''}
                                                  aria-pressed={selectedColor === productSizeColorOption.productColor?.hexCode}
                                                  style={{
                                                    background: productSizeColorOption.productColor?.hexCode || productSizeColorOption.productColor?.rgb || '',
                                                    borderWidth: selectedColor === productSizeColorOption.productColor?.hexCode ? '4px' : '1px',
                                                    borderColor: selectedColor === productSizeColorOption.productColor?.hexCode ? '#D3D4D4' : '#D3D4D4',
                                                    borderRadius: '50%',
                                                    width: '2.5rem',
                                                    height: '2.5rem ',
                                                    animation: selectedColor === productSizeColorOption.productColor?.hexCode ? 'borderAnimation 1s infinite' : 'none'
                                                  }}
                                                  onClick={() => {
                                                    productSizeColorOption.productColor?.hexCode && setSelectedColor(productSizeColorOption.productColor.hexCode)
                                                    if (selectedChild) {
                                                      setShopItemQuantity(1)
                                                      setSelectedImageIndex(0)
                                                      setSelectedChild({
                                                        ...selectedChild,
                                                        ...productSizeColorOption
                                                      })
                                                    }
                                                  }}
                                                  onKeyDown={(event) => {
                                                    if (event.key === 'Enter' || event.key === ' ') {
                                                      productSizeColorOption.productColor?.hexCode && setSelectedColor(productSizeColorOption.productColor.hexCode)
                                                      if (selectedChild) {
                                                        setShopItemQuantity(1)
                                                        setSelectedImageIndex(0)
                                                        setSelectedChild({
                                                          ...selectedChild,
                                                          ...productSizeColorOption
                                                        })
                                                      }
                                                    }
                                                  }}
                                                />
                                              </Tooltip>
                                            ))
                                          )
                                    }
                                  </div>
                                </>
                                )
                              : (
                                <div className="col">
                                  <h6>Choose a variation</h6>
                                  {
                                      isLoadingProduct
                                        ? (
                                          <div className="row row-cols-3 row-cols-sm-3 row-cols-md-6 g-2">
                                            <ChildProductLoader repetition={6} />
                                          </div>
                                          )
                                        : (
                                            (productSizeVariations.length > 0)
                                              ? (
                                                <div className="row row-cols-3 row-cols-sm-3 row-cols-md-6 g-2">
                                                  {
                                                    (productSizeVariations).map((productSizeVariation, index) => (
                                                      <Tooltip key={`${productSizeVariation.id}-${index}`} text={productSizeVariation.name} placement="bottom">
                                                        <div className="col rounded-5">
                                                          <div className={`product-child-card card h-100 ${selectedChild && selectedChild.id === productSizeVariation.id ? 'selected' : ''}`}
                                                            tabIndex={0}
                                                            role="button"
                                                            onClick={() => {
                                                              if (selectedChild) {
                                                                productSizeVariation.productColor && productSizeVariation.productColor.hexCode ? setSelectedColor(productSizeVariation.productColor.hexCode) : setSelectedColor(null)
                                                                setShopItemQuantity(1)
                                                                setSelectedImageIndex(0)
                                                                setSelectedChild({
                                                                  ...selectedChild,
                                                                  ...productSizeVariation
                                                                })
                                                              }
                                                            }}
                                                            onKeyDown={(event) => {
                                                              if (event.key === 'Enter' || event.key === ' ') {
                                                                if (selectedChild) {
                                                                  productSizeVariation.productColor && productSizeVariation.productColor.hexCode ? setSelectedColor(productSizeVariation.productColor.hexCode) : setSelectedColor(null)
                                                                  setShopItemQuantity(1)
                                                                  setSelectedImageIndex(0)
                                                                  setSelectedChild({
                                                                    ...selectedChild,
                                                                    ...productSizeVariation
                                                                  })
                                                                }
                                                              }
                                                            }}
                                                          >
                                                            <img
                                                              src={productSizeVariation.pictures && productSizeVariation.pictures.length > 0 ? productSizeVariation.pictures[0].publicUrl : Placeholder}
                                                              alt=""
                                                              className="img-fluid rounded-5"
                                                              style={{
                                                                objectFit: productSizeVariation.pictures && productSizeVariation.pictures.length > 0 ? 'contain' : 'cover'
                                                              }}
                                                              onError={(e) => {
                                                                const target = e.target as HTMLImageElement
                                                                target.onerror = null
                                                                target.src = Placeholder
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </Tooltip>
                                                    ))
                                                  }
                                                </div>
                                                )
                                              : (<p className="text-muted">Color options are not available</p>)
                                          )
                                    }
                                </div>
                                )
                          )
                        : (
                            isLoadingProductSizes
                              ? (
                                <p className="placeholder-glow">
                                  <span className="placeholder col-6"></span>
                                </p>
                                )
                              : (
                                <p className="text-muted">{(productSizes.length > 0 && (selectedChild && selectedChild.children.length > 0)) ? 'Select a size to view variations' : 'No variations available'}</p>
                                )
                          )
                    )
              }
            </div>
            <div className="col-md-6 col-sm-12 gy-4 gy-md-0">
              <h6>Price<span className="text-muted ms-1 fw-lighter small">(exc. VAT)</span></h6>
              {
                isLoadingProduct
                  ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            {
                              Array.from(Array(4).keys()).map((n: number) => (
                                <th scope="col" key={n}>
                                  <p className="placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                  </p>
                                </th>
                              ))
                            }
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                          {
                            Array.from(Array(4).keys()).map((n: number) => (
                              <td scope="col" key={n}>
                                <p className="placeholder-glow">
                                  <span className="placeholder col-12"></span>
                                </p>
                              </td>
                            ))
                          }
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    )
                  : (
                      graduatedPrices.length > 0
                        ? (
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Units</th>
                                  {
                                    graduatedPricesSorted.map(graduatedPrice => (
                                      <th key={graduatedPrice.id} scope="col">{graduatedPrice.firstUnit} - {graduatedPrice.lastUnit}</th>
                                    ))
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Price</td>
                                  {
                                    graduatedPricesSorted.map(graduatedPrice => (
                                      <td key={`${graduatedPrice.price}-${graduatedPrice.lastUnit}`}>{formatPrice('EUR', navigator.language).format(graduatedPrice.price)}</td>
                                    ))
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          )
                        : (<p className="text-muted">{selectedChild && formatPrice(selectedChild.netRetailPrice.currency || 'EUR', navigator.language).format(selectedChild.netRetailPrice.amount)}</p>)
                    )
              }
            </div>
          </div>
          <div className="row my-4 border-top">
            <div className="col mt-4">
              <h6 className="fw-semibold">Similar products</h6>
              {
                isLoadingSimilarProducts
                  ? (
                    <div className="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-2">
                      <SimilarProductLoader repetition={6} />
                    </div>
                    )
                  : (
                      (similarProducts).length > 0
                        ? (
                          <div className="row row-cols-2 row-cols-sm-2 row-cols-md-6 g-2">
                            {
                              ((similarProducts).slice(0, 6)).map((product, index) => (
                                <Tooltip key={`${product.id}-${index}`} text={product.name} placement="bottom">
                                  <div
                                    className="col rounded"
                                    role="button"
                                    onClick={() => {
                                      navigate(`/shop/${product.id}`)
                                    }}
                                    onKeyDown={(event) => {
                                      if (event.key === 'Enter' || event.key === ' ') {
                                        navigate(`/shop/${product.id}`)
                                      }
                                    }}
                                  >
                                    <img
                                      src={product.pictures && product.pictures.length > 0 ? product.pictures[0].publicUrl : Placeholder}
                                      alt=""
                                      className="img-fluid rounded border h-100"
                                      style={{
                                        objectFit: product.pictures && product.pictures.length > 0 ? 'contain' : 'cover',
                                        maxHeight: 180
                                      }}
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement
                                        target.onerror = null
                                        target.src = Placeholder
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              ))
                            }
                          </div>
                          )
                        : (
                          <p className="small">No similar products available</p>
                          )
                    )
              }
            </div>
          </div>
        </div>
      </div>
      {/* Modals */}
      <ShoppingModals
        selectedMultipleAddresses = {selectedMultipleAddresses}
        setSelectedMultipleAddresses = {setSelectedMultipleAddresses}
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
        isAllowedToWriteDeliveryAddresses={true}
        loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
      />
    </main>
  )
}

export default ShopItemDetails
