import { ShoppingCartBundle } from '../types'
import {
  allowedFillingMaterialTagNames,
  allowedGreetingCardsTagNames,
  allowedPackagingTagNames,
  allowedTissuePaperTagNames
} from './allowedBundleTags'

export const validateShoppingCartBundles = (
  shoppingCartBundles: ShoppingCartBundle[]
): boolean => {
  if (shoppingCartBundles.length === 0) return false

  const categoryMap = [
    { tags: allowedPackagingTagNames },
    { tags: allowedFillingMaterialTagNames },
    { tags: allowedGreetingCardsTagNames },
    { tags: allowedTissuePaperTagNames }
  ]
  return shoppingCartBundles.every((bundle) => {
    let hasPackaging = false
    let hasFillingMaterial = false
    let hasBundleContent = false

    bundle.products.forEach((product) => {
      const productTags = product.productTags.map((tag) =>
        tag.productCategoryTag.name.toLowerCase()
      )

      const category = categoryMap.find((cat) =>
        productTags.some((tag) => cat.tags.includes(tag))
      )

      if (category) {
        if (category.tags === allowedPackagingTagNames) {
          hasPackaging = true
        } else if (category.tags === allowedFillingMaterialTagNames) {
          hasFillingMaterial = true
        }
      } else {
        hasBundleContent = true
      }
    })

    return hasPackaging && hasFillingMaterial && hasBundleContent
  })
}
