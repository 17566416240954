import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import parse from 'html-react-parser'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import Placeholder from '../../../assets/images/placeholder.png'
import PendingOrder from '../../../components/MyInventory/Details/PendingOrder'
import InboundDelivery from '../../../components/MyInventory/Details/InboundDelivery'
import * as userRoles from '../../../constants/userRoles'
import * as appModules from '../../../constants/appModules'
import {
  editAProductById,
  getAProductById,
  getAProductInbounds,
  getAProductOutbounds,
  getAProductStock,
  resetProductMessage
} from '../../../store/reducers/api/productReducer'
import { Module, Permission } from '../../../types'
import hasPermission from '../../../utils/checkPermissions'
import { READ } from '../../../constants/permissions'
import { getAProductStockSerialNumbers } from '../../../store/reducers/api/dotnet/productReducer'
import SerialNumber from '../../../components/MyInventory/Details/SerialNumber'
import Pagination from '../../../components/Pagination'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import OutboundOrder from '../../../components/MyInventory/Details/OutboundOrder'
import InboundOrder from '../../../components/MyInventory/Details/InboundOrder'
import Loader from './Loader'
import GraduatedPricesListView from '../../../components/MyInventory/GraduatedPrices/GraduatedPricesListView'
import {
  PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE,
  PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE,
  PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE,
  PRODUCT_UPDATE_MESSAGE
} from '../../../constants/messages'
import { setToast } from '../../../store/reducers/toastReducer'
import { resetProductGraduatedPriceMessage } from '../../../store/reducers/api/productGraduatedPriceReducer'
import ProductDescriptionRichTextEditor from './ProductDescriptionRichTextEditor'
import { dismissModal } from '../../../utils/dismissModal'
import Progress from '../../../components/loaders/Progress'

const InventoryDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const product = useAppSelector((state) => state.apiProduct.product)
  const messageProduct = useAppSelector((state) => state.apiProduct.message)
  const stock = useAppSelector((state) => state.apiProduct.stock)
  const isLoading = useAppSelector((state) => state.apiProduct.isLoading)
  const stockProducts = useAppSelector((state) => state.dotnetApiProduct.stockProducts)
  const metadata = useAppSelector((state) => state.dotnetApiProduct.stockProductsMetadata)
  const isLoadingStockProducts = useAppSelector((state) => state.dotnetApiProduct.isLoading)
  const outbounds = useAppSelector((state) => state.apiProduct.outbounds)
  const isLoadingOutbounds = useAppSelector((state) => state.apiProduct.isLoadingOutbounds)
  const outboundsMetadata = useAppSelector((state) => state.apiProduct.outboundsMetadata)
  const inbounds = useAppSelector((state) => state.apiProduct.inbounds)
  const isLoadingInbounds = useAppSelector((state) => state.apiProduct.isLoadingInbounds)
  const inboundsMetadata = useAppSelector((state) => state.apiProduct.inboundsMetadata)
  const isLoadingProductGraduatedPrice = useAppSelector((state) => state.apiProductGraduatedPrice.isLoading)
  const messageProductGraduatedPrice = useAppSelector((state) => state.apiProductGraduatedPrice.message)

  const [perPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)

  const [perPageOutbounds, setPerPageOutbounds] = useState(5)
  const [pageOutbounds, setPageOutbounds] = useState(1)

  const [perPageInbounds, setPerPageInbounds] = useState(5)
  const [pageInbounds, setPageInbounds] = useState(1)

  const quillRef = useRef<any>(null)

  const allowedRoles = [userRoles.ADMIN, userRoles.COMPANYADMINISTRATOR]
  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const dispatch = useAppDispatch()
  const { productId } = useParams()

  const token = currentUser?.token
  const role = currentUser?.role || userRoles.USER
  const userId = currentUser?.id
  const companyOwnerId = currentUser?.company?.owner?.id

  const isOwner = companyOwnerId && userId === companyOwnerId

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const productDescriptionSchema = object().shape({
    description: string()
      .label('Description')
      .max(10000, (value) => `${value.label} must be at most ${value.max} ${value.max === 1 ? 'character' : 'characters'}`)
  })

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleShowEntriesOutbounds = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageOutbounds(1)
    setPerPageOutbounds(Number(event.target.value))
  }

  const handlePageChangeOutbounds = (page: number) => {
    setPageOutbounds(page)
  }

  const handleShowEntriesInbounds = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageInbounds(1)
    setPerPageInbounds(Number(event.target.value))
  }

  const handlePageChangeInbounds = (page: number) => {
    setPageInbounds(page)
  }

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    validationSchema: productDescriptionSchema,
    initialValues: {
      description: product?.description || ''
    },
    onSubmit: ({ description }, actions) => {
      const controller = new AbortController()
      const signal = controller.signal
      if (token && productId) {
        dispatch(editAProductById({ token, productId, product: { description }, signal }))
      }
      actions.setSubmitting(false)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId) {
      dispatch(getAProductById({ token, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE, PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE, PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE, PRODUCT_UPDATE_MESSAGE]

    if (token && productId) {
      if ((messageProductGraduatedPrice && allowedMessages.includes(messageProductGraduatedPrice))) {
        const payload = {
          title: 'Success',
          message: messageProductGraduatedPrice,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'success'
        }
        dispatch(setToast(payload))
        dispatch(resetProductGraduatedPriceMessage())
        dispatch(getAProductById({ token, productId, signal }))
      }
      if ((messageProduct && allowedMessages.includes(messageProduct))) {
        dismissModal('productDescriptionModal')
        const payload = {
          title: 'Success',
          message: messageProduct,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'success'
        }
        dispatch(setToast(payload))
        dispatch(resetProductMessage())
        dispatch(getAProductById({ token, productId, signal }))
      }
    }
  }, [messageProductGraduatedPrice, messageProduct])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const merchantSku = product?.merchantSku

    if (token && merchantSku && isAllowed(appModules.PRODUCTS)) {
      const encodedMerchantSku = encodeURIComponent(merchantSku)
      dispatch(getAProductStockSerialNumbers({ token, merchantSku: encodedMerchantSku, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [product, perPage, page])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId && isAllowed(appModules.PRODUCTS)) {
      dispatch(getAProductStock({ token, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId && isAllowed(appModules.PRODUCTS)) {
      dispatch(getAProductOutbounds({ token, perPage: perPageOutbounds, page: pageOutbounds, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId, perPageOutbounds, pageOutbounds])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId && isAllowed(appModules.PRODUCTS)) {
      dispatch(getAProductInbounds({ token, perPage: perPageInbounds, page: pageInbounds, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId, perPageInbounds, pageInbounds])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="row">
          <div className="col-12">
            <div className="card overflow-hidden">
              {
                (isLoading)
                  ? (<Loader />)
                  : (
                    <div className="row p-4">
                      <div className="col-lg-5">
                        <span className="text-center d-block mb-4">
                          {
                            product && product.pictures && product?.pictures.length > 0
                              ? (
                                <img
                                  src={product.pictures[0].publicUrl}
                                  className="img-fluid"
                                  style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }}
                                  alt="Product-img"
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement
                                    target.onerror = null
                                    target.src = Placeholder
                                  }}
                                />
                                )
                              : (<img src={Placeholder} alt="Placeholder thumbnail" className="img-fluid" style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }} />)
                          }
                        </span>

                        <div className="d-lg-flex d-none justify-content-center">
                          {
                            product && product.pictures && product.pictures.length > 0
                              ? (
                                  product.pictures.map(picture => (
                                    <span key={picture.number} className={`${picture.number > 1 ? 'ms-2' : 'ms-0'}`}>
                                      <img
                                        src={picture.publicUrl}
                                        className="img-fluid img-thumbnail p-2"
                                        style={{ maxWidth: '75px' }}
                                        alt="Product"
                                        onError={(e) => {
                                          const target = e.target as HTMLImageElement
                                          target.onerror = null
                                          target.src = Placeholder
                                        }}
                                      />
                                    </span>
                                  ))
                                )
                              : (null)
                          }
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="ps-lg-4">
                          <h3 className="mt-0">{product?.name}</h3>
                          <p className="mb-1">
                            Article Number (SKU): {product?.merchantSku}
                          </p>

                              {
                                allowedRoles.includes(role) && (
                                  <div className="mt-4">
                                    <h4>Inventory</h4>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <h6 className="font-14">In Stock / Available:</h6>
                                        <p className="text-sm lh-150">{stock?.stockLevel || 0} / {Math.max((stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0), 0)}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <h6 className="font-14">Reserved:</h6>
                                        <p className="text-sm lh-150">{stock?.stockLevelReserved || 0}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <h6 className="font-14">Awaiting Delivery:</h6>
                                        <p className="text-sm lh-150">{stock?.stockLevelAnnounced || 0}</p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                        </div>
                      </div>
                    </div>
                    )
              }
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title mb-0">Product Description</h5>
                  {role === userRoles.ADMIN && <button
                    className="btn btn-outline-primary btn-sm text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#productDescriptionModal"
                    disabled={isLoading}
                  >
                    <i className="bi bi-pencil-square"></i><span className="ms-1 d-none d-md-inline">Edit Description</span>
                  </button>}
                </div>
                <div className="card-text product-description">
                  {isLoading
                    ? (
                      <>
                        <p className="placeholder-glow">
                          <span className="placeholder col-7"></span>
                          <span className="placeholder col-4"></span>
                          <span className="placeholder col-4"></span>
                          <span className="placeholder col-6"></span>
                          <span className="placeholder col-8"></span>
                        </p>
                      </>
                      )
                    : (
                        product?.description
                          ? parse(product.description)
                          : <p>No description available.</p>
                      )}
                </div>
              </div>
            </div>
            <div className="card mt-4">
              <div className="m-4">
                <GraduatedPricesListView
                  isLoading={isLoading || isLoadingProductGraduatedPrice}
                  graduatedPrices={product?.graduatedPrices ?? []}
                  productId={product?.id}
                />
              </div>
            </div>
            <div className="card mt-4">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-archive me-1"></i> Serial Numbers <br />
                    <span className="small">Serial numbers included in the following orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order Number</th>
                        <th>Recipients</th>
                        <th>Serial Number</th>
                        <th>Date Dispatched</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoadingStockProducts
                          ? (
                              Array.from(Array(perPage).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : stockProducts.length > 0
                            ? (
                                stockProducts.map((stockProduct) => (
                                <SerialNumber stockProduct={stockProduct} key={stockProduct.id}/>
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No serial numbers available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="mt-2">
                  <Pagination
                    isLoading={isLoadingStockProducts}
                    metadata={{
                      limit: metadata.perPage,
                      total: metadata.total,
                      offset: ((metadata.page - 1) * (metadata.perPage))
                    }}
                    page={page}
                    perPage={perPage}
                    perPageOptions={[5, 10, 25, 50]}
                    handlePageChange={handlePageChange}
                    handleShowEntries={handleShowEntries}
                    module="StockProducts"
                  />
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-archive me-1"></i> Pending Orders <br />
                    <span className="small">Product reserved in the following orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order ID</th>
                        <th>Recipients</th>
                        <th className="text-center">Number of boxes</th>
                        <th>Date Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoading
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : stock && stock?.stockReservedDetails.length > 0
                            ? (
                                stock.stockReservedDetails.map((pendingOrder) => (
                                <PendingOrder outboundId={pendingOrder.outboundId} key={pendingOrder.outboundId}/>
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No pending orders available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card mt-4 overflow-hidden">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-archive me-1"></i> Product Delivery <br />
                    <span className="small">Status of inbound deliveries</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Delivery ID</th>
                        <th>Ordered Quantity</th>
                        <th className="text-center">Delivered Quantity</th>
                        <th>Expected Date of Delivery</th>
                        <th>Date of Delivery</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoading
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={0} />)
                            )
                          : stock && stock.stockAnnouncedDetails.length > 0
                            ? (
                                stock.stockAnnouncedDetails.map((inbound) => (
                                <InboundDelivery
                                  key={inbound.inboundId}
                                  inboundId={inbound.inboundId}
                                  jfsku={String(product?.jfsku)}
                                />
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  No inbound deliveries available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card mt-4 overflow-hidden">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-clock-history me-1"></i> Product History <br />
                    <span className="small">Inbound orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order Id</th>
                        <th className="text-center">Delivered Quantity</th>
                        <th>Delivery Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoadingInbounds
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : inbounds && inbounds.length > 0
                            ? (
                                inbounds.map((inbound) => (
                                <InboundOrder
                                  key={inbound.inboundId}
                                  inbound={inbound}
                                  jfsku={String(product?.jfsku)}
                                />
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No inbound orders available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="mt-2">
                  <Pagination
                    isLoading={isLoadingInbounds}
                    metadata={{
                      limit: inboundsMetadata.perPage,
                      total: inboundsMetadata.total,
                      offset: ((inboundsMetadata.page - 1) * (inboundsMetadata.perPage))
                    }}
                    page={pageInbounds}
                    perPage={perPageInbounds}
                    perPageOptions={[5, 10, 25, 50]}
                    handlePageChange={handlePageChangeInbounds}
                    handleShowEntries={handleShowEntriesInbounds}
                    module="ProductInbounds"
                  />
                </div>
              </div>
            </div>

            <div className="card mt-4 overflow-hidden">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-clock-history me-1"></i> Product History <br />
                    <span className="small">Outbound orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order Id</th>
                        <th className="text-center">Delivered Quantity</th>
                        <th>Shipping Date</th>
                        <th>Created By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoadingOutbounds
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : outbounds && outbounds.length > 0
                            ? (
                                outbounds.map((outbound) => (
                                <OutboundOrder
                                  key={outbound.outboundId}
                                  outbound={outbound}
                                />
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No outbound orders available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="mt-2">
                  <Pagination
                    isLoading={isLoadingOutbounds}
                    metadata={{
                      limit: outboundsMetadata.perPage,
                      total: outboundsMetadata.total,
                      offset: ((outboundsMetadata.page - 1) * (outboundsMetadata.perPage))
                    }}
                    page={pageOutbounds}
                    perPage={perPageOutbounds}
                    perPageOptions={[5, 10, 25, 50]}
                    handlePageChange={handlePageChangeOutbounds}
                    handleShowEntries={handleShowEntriesOutbounds}
                    module="ProductOutbounds"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Description Modal */}
      <div className="modal fade" id="productDescriptionModal" tabIndex={-1} aria-labelledby="productDescriptionModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="productDescriptionModalLabel">Edit Product Description</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit}>
              {
                isLoading && (<Progress marginBottom={false} />)
              }
              <div className="modal-body">
                <ProductDescriptionRichTextEditor
                  ref={quillRef}
                  handleTextChange={(value) => {
                    setFieldValue('description', value)
                  }}
                  textContent={values.description}
                  stageWidth={'100%'}
                  className={`product-description ${
                    touched.description &&
                    errors.description
                      ? 'is-invalid'
                      : ''
                  }`}
                  border={(touched.description && errors.description) ? '1px solid #dc3545' : 'none'}
                  readOnly={false}
                />
                <div
                  id="validationProductDescriptionFeedback"
                  className="invalid-feedback"
                >
                  {errors.description}
                </div>
              </div>
              {
                isLoading && (<Progress marginBottom={false} />)
              }
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting || isLoading}
                >
                  <i className="bi bi-save me-1"></i> Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  )
}

export default InventoryDetails
