import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { object, string, number } from 'yup'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Select from 'react-select'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Product } from '../../types'
import Placeholder from '../../assets/images/placeholder.png'
import {
  COMPANY_DOMAIN_UNVERIFIED_MESSAGE,
  EMAIL_UNVERIFIED_MESSAGE, PRODUCT_COMPANY_UPDATE_MESSAGE, PRODUCT_UPDATE_MESSAGE, UNAUTHORIZED_401_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import Pagination from '../../components/Pagination'
import { editAProductById, getAllProducts, resetProductMessage, updateAProductCompanyById } from '../../store/reducers/api/productReducer'
import * as userRoles from '../../constants/userRoles'
import { getCompanyProducts, getCompanies } from '../../store/reducers/api/companyReducer'
import TableColumnStock from './components/TableColumnStock'
import useDebounce from '../../utils/hooks/useDebounce'
import { dismissModal } from '../../utils/dismissModal'
import { PencilIcon } from '../../components/icons/PencilIcon'

dayjs.extend(localizedFormat)

const Inventory = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = currentUser?.role === userRoles.ADMIN
    ? useAppSelector((state) => state.apiProduct.isLoading)
    : useAppSelector((state) => state.apiCompany.isLoading)
  const products = currentUser?.role === userRoles.ADMIN
    ? useAppSelector((state) => state.apiProduct.products)
    : useAppSelector((state) => state.apiCompany.products)
  const metadata = currentUser?.role === userRoles.ADMIN
    ? useAppSelector((state) => state.apiProduct.metadata)
    : useAppSelector((state) => state.apiCompany.productMetadata)
  const error = useAppSelector((state) => state.apiProduct.error)
  const message = useAppSelector((state) => state.apiProduct.message)
  const companies = useAppSelector((state) => state.apiCompany.companies)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const [initialProduct, setInitialProduct] = useState<Partial<Product>>({
    id: '',
    minimumOrderQuantity: 1,
    isExceedStockEnabled: false,
    company: {
      id: '',
      customerId: 0,
      name: '',
      email: '',
      domainVerificationCode: {
        value: null,
        createdAt: null
      },
      isDomainVerified: false
    }
  })

  // Prevent loading stocks for all products when navigating from campaign details page
  // or any page that fetches numerous products
  const mappedProducts = products.slice(0, perPage)

  const companyId = currentUser?.company?.id
  const role = currentUser?.role || userRoles.USER
  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [searchTerm, setSearchTerm] = useState<string>(searchParams.get('search') || '')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
    localStorage.setItem('perPageInventory', event.target.value)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    localStorage.setItem('pageInventory', String(page))
  }

  const searchSchema = object({
    search: string().max(48, 'Search Name is too long')
  })

  const companySchema = object({
    companyId: string().nullable()
  })

  const productSchema = object({
    minimumOrderQuantity: number().label('Minimum Order Quantity').min(1)
  })

  const handleInventoryRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getAllProducts({ token, perPage, page, search: debouncedSearchTerm, signal }))
      } else {
        companyId && dispatch(getCompanyProducts({ id: companyId, token, perPage, page, search: debouncedSearchTerm, signal }))
      }
    }
  }

  useEffect(() => {
    if (
      (error && error.message === UNAUTHORIZED_401_MESSAGE)
    ) {
      const payload = {
        title: 'Session Expired',
        message: 'Refresh your session',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }

    if (error && error?.errors.message === EMAIL_UNVERIFIED_MESSAGE) {
      const payload = {
        title: 'Verification Pending',
        message: 'Please verify your email address in the Profile section',
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }

    if (error && error?.errors.message === COMPANY_DOMAIN_UNVERIFIED_MESSAGE) {
      const payload = {
        title: 'Verification Pending',
        message: "Please verify your company domain in the 'My Company' section",
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      // Update search params
      const params = new URLSearchParams(searchParams)

      if (debouncedSearchTerm) {
        params.set('search', debouncedSearchTerm)
      } else {
        params.delete('search')
      }
      navigate(`?${params.toString()}`, { replace: true })
      if (role === userRoles.ADMIN) {
        dispatch(getAllProducts({ token, perPage, page, search: debouncedSearchTerm, signal }))
      } else {
        companyId && dispatch(getCompanyProducts({ id: companyId, token, perPage, page, search: debouncedSearchTerm, signal }))
      }
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm, companyId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    token && role === userRoles.ADMIN && dispatch(getCompanies({ token, perPage: 100, page: 1, signal }))
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && message && role === userRoles.ADMIN) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getAllProducts({ token, perPage, page, search: debouncedSearchTerm, signal }))
      if (message === PRODUCT_UPDATE_MESSAGE) {
        dismissModal('updateProduct')
      }
      if (message === PRODUCT_COMPANY_UPDATE_MESSAGE) {
        dismissModal('updateProductCompany')
      }
      dispatch(resetProductMessage())
    }
  }, [message])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-archive me-1"></i> My Inventory</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{
                      search: searchTerm
                    }}
                    onSubmit={({ search }, actions) => {
                      setSearchTerm(search)
                      if (page !== 1) {
                        setPage(1)
                        navigate('')
                      }

                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              const search = event.target.value
                              handleChange(event)
                              setSearchTerm(search)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            maxLength={48}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${
                              errors.search &&
                              touched.search &&
                              errors.search
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="btnNavbarSearch"
                            name="search"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="btnNavbarSearch"
                            type="submit"
                            title="Search"
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <button
                  type="button"
                  title="Refresh"
                  aria-label="Refresh"
                  className="btn btn-outline-dark"
                  onClick={() => handleInventoryRefresh()}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </ul>
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Name</th>
                    <th scope="col" className="text-nowrap">In Stock/ Available</th>
                    {
                      role === userRoles.ADMIN && <th scope="col">Company</th>
                    }
                    <th scope="col" className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoading
                      ? (
                          Array.from(Array(perPage).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={role === userRoles.ADMIN ? 6 : 5} actionQuantity={role === userRoles.ADMIN ? 3 : 1} hasThumbnail />)
                        )
                      : (
                          mappedProducts.length > 0
                            ? (mappedProducts.map((product: Product) => (
                              <tr key={product.id} className={initialProduct.id === product.id ? 'table-primary' : ''}>
                                <td>
                                  {
                                    product.pictures && product.pictures.length > 0
                                      ? (
                                        <img
                                          src={product.pictures[0].publicUrl}
                                          alt="Product thumbnail"
                                          className="product-thumbnail"
                                          onError={(e) => {
                                            const target = e.target as HTMLImageElement
                                            target.onerror = null
                                            target.src = Placeholder
                                          }}
                                        />
                                        )
                                      : (<img src={Placeholder} alt="Placeholder thumbnail" className="product-thumbnail" />)
                                  }
                                </td>
                                <td>{product.merchantSku}</td>
                                <td>
                                  <Link className="text-decoration-none"
                                    to={`/inventory/${product.id}`}
                                    state={{ searchParams: new URLSearchParams(location.search).toString(), from: 'inventory' }}
                                  >
                                    {product.name}
                                  </Link>
                                </td>
                                <TableColumnStock stock={product.stock} />
                                {
                                  role === userRoles.ADMIN && <td>{product.company?.name ?? '---'}</td>
                                }
                                <td className="text-end">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                    <button
                                      className="btn btn-outline-dark btn-round"
                                      type="button"
                                      title="Details"
                                      onClick={() => {
                                        navigate(`/inventory/${product.id}`, { state: { searchParams: new URLSearchParams(location.search).toString(), from: 'inventory' } })
                                      }}
                                    >
                                      <i className="bi bi-arrow-right"></i>
                                    </button>
                                    {
                                      role === userRoles.ADMIN && (
                                        <>
                                          <button
                                            className="btn btn-outline-dark btn-round ms-2"
                                            type="button"
                                            title="Update Product Company"
                                            data-bs-toggle="modal"
                                            data-bs-target="#updateProductCompany"
                                            onClick={() => {
                                              setInitialProduct(product)
                                            }}
                                          >
                                            <i className="bi bi-building"></i>
                                          </button>
                                          <button
                                            className="btn btn-outline-dark btn-round ms-2"
                                            type="button"
                                            title="Update Product"
                                            data-bs-toggle="modal"
                                            data-bs-target="#updateProduct"
                                            onClick={() => {
                                              setInitialProduct(product)
                                            }}
                                          >
                                            <PencilIcon/>
                                          </button>
                                        </>
                                      )
                                    }
                                  </div>
                                </td>
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={role === userRoles.ADMIN ? 6 : 5} className="text-center">
                                  No products available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              isTrackingPage
            />
          </div>
        </div>
      </div>
      {/* Modals */}
      <div
        className="modal fade"
        id="updateProductCompany"
        tabIndex={-1}
        aria-labelledby="updateProductCompanyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="updateProductCompanyLabel">
                <i className="bi bi-pencil-square me-2"></i>Update Product Company
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 mt-0 opacity-50"></hr>}
            <div className="modal-body">
              <p>
                Update{' '}
                <b>{` '${initialProduct.name}' `}</b>
                with sku<b>{` '${initialProduct.merchantSku}'`}</b>
              </p>
              <div>
                <Formik
                  validationSchema={companySchema}
                  enableReinitialize
                  initialValues={{
                    companyId: initialProduct?.company?.id
                  }}
                  onSubmit={({ companyId }, actions) => {
                    const controller = new AbortController()
                    const signal = controller.signal
                    const product = {
                      companyId: companyId || null
                    }
                    const productId = initialProduct?.id
                    if (token && productId) {
                      dispatch(updateAProductCompanyById({ productId, token, product, signal }))
                    }
                    actions.setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              htmlFor="productCompany"
                              className="form-label"
                            >
                              Company
                            </label>
                            <Select
                              className={`${
                                ((errors.companyId) && touched.companyId)
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor,
                                  '&:hover': {
                                    borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor
                                  }
                                })
                              }}
                              isClearable
                              inputId="productCompany"
                              name="companyId"
                              aria-label="Company"
                              options={companies}
                              getOptionLabel={(company) => `${company.name}`}
                              getOptionValue={(company) => String(company.id)}
                              onChange={(selectedOption) => setFieldValue('companyId', selectedOption?.id)}
                              onBlur={handleBlur}
                              isLoading={isLoading}
                              value={companies.find((company) => company.id === values.companyId)}
                            />
                            <div
                              id="validationProductCompanyFeedback"
                              className="invalid-feedback"
                            >
                              {errors.companyId}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-primary mt-2"
                          disabled={isSubmitting || isLoading}
                        >
                          <i className="bi bi-save"></i> Save
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="updateProduct"
        tabIndex={-1}
        aria-labelledby="updateProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="updateProductLabel">
                <i className="bi bi-pencil-square me-2"></i>Update Product
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 mt-0 opacity-50"></hr>}
            <div className="modal-body">
              <p>
                Update{' '}
                <b>{` '${initialProduct.name}' `}</b>
                with sku<b>{` '${initialProduct.merchantSku}'`}</b>
              </p>
              <div>
                <Formik
                  validationSchema={productSchema}
                  enableReinitialize
                  initialValues={{
                    minimumOrderQuantity: initialProduct.minimumOrderQuantity,
                    isExceedStockEnabled: initialProduct.isExceedStockEnabled
                  }}
                  onSubmit={({ minimumOrderQuantity, isExceedStockEnabled }, actions) => {
                    const controller = new AbortController()
                    const signal = controller.signal
                    const product = {
                      minimumOrderQuantity: minimumOrderQuantity || 1,
                      isExceedStockEnabled
                    }
                    const productId = initialProduct?.id
                    if (token && productId) {
                      dispatch(editAProductById({ productId, token, product, signal }))
                    }
                    actions.setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="minimumOrderQuantity" className="form-label">
                              Minimum Order Quantity
                            </label>
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              className={`form-control ${
                                touched.minimumOrderQuantity &&
                                errors.minimumOrderQuantity
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              min={1}
                              value={values.minimumOrderQuantity}
                              id="minimumOrderQuantity"
                              name="minimumOrderQuantity"
                              placeholder="Enter Minimum Order Quantity"
                              autoComplete="off"
                            />
                            <div
                              id="validationMinimumOrderQuantityFeedback"
                              className="invalid-feedback"
                            >
                              {errors.minimumOrderQuantity}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              htmlFor="isExceedStockEnabled"
                              className="form-label"
                            >
                              Allow Over Ordering <span className="fw-semibold">(Stock)</span>
                            </label>
                            <select
                              aria-label="Allow Over Ordering (Stock)"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={String(values.isExceedStockEnabled)}
                              className={`form-select ${
                                touched.isExceedStockEnabled &&
                                errors.isExceedStockEnabled
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              id="isExceedStockEnabled"
                              name="isExceedStockEnabled"
                            >
                              <option value="">Select Allow Over Ordering</option>
                              <option value={'true'}>Yes</option>
                              <option value={'false'}>No</option>
                            </select>
                            <div
                              id="validationIsExceedStockEnabledFeedback"
                              className="invalid-feedback"
                            >
                              {errors.isExceedStockEnabled}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-primary mt-2"
                          disabled={isSubmitting || isLoading}
                        >
                          <i className="bi bi-save"></i> Save
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Inventory
