import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useAppSelector } from '../../../store/hooks'
import { Item, Nullable, Order } from '../../../types'
import { Link } from 'react-router-dom'
import OutboundService from '../../../services/api/OutboundService'

const PendingOrder = ({ outboundId }: { outboundId: string }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const token = currentUser?.token

  const [order, setOrder] = useState<Nullable<Order>>(null)
  const [, setError] = useState<any>(null)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      OutboundService.getASingleOutbound(token, outboundId, signal).then((response: any) => {
        setOrder(response.data.outbound)
      }).catch((error: any) => setError(error.message))
    }

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <tr>
      <td>
        <Link title="View Order Details" className="text-decoration-none" to={`/all-orders/${outboundId}`}>
          {outboundId}
        </Link>
      </td>
      <td>{`${order?.shippingAddress.firstname ?? ''} ${order?.shippingAddress.lastname ?? ''}`}</td>
      <td className="text-center">
        <span className="badge text-bg-secondary">{order?.items.filter((item: Item) => item.itemType === 'BillOfMaterials').reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0) || (order && Math.max(...order.items.map(item => item.quantity)))} </span>
      </td>
      <td>{dayjs(order?.modificationInfo.createdAt).format('llll')}</td>
    </tr>
  )
}

export default PendingOrder
