import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Formik } from 'formik'
import { number, object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addPictureToBundle, getBundleById } from '../../../store/reducers/api/bundleReducer'
import {
  refFirebase, getDownloadURLFirebase,
  getMetadataFirebase, storageFirebase, uploadBytesResumableFirebase
} from '../../../config/firebaseConfig'
import { Picture } from '../../../types'
import { PICTURE_DELETION_MESSAGE, PICTURE_POSTED_SUCCESS_MESSAGE } from '../../../constants/messages'
import { deletePictureById, resetPictureMessage } from '../../../store/reducers/api/pictureReducer'
import dayjs from 'dayjs'
import { setToast } from '../../../store/reducers/toastReducer'
import ImagePlaceholder from '../../../assets/images/cd-icon-picture.svg'
import { TrashIcon } from '../../icons/TrashIcon'

const firebaseStorageEnvironment = process.env.REACT_APP_FIREBASE_STORAGE_ENVIRONMENT || 'develop'

const PictureUploader = ({ id }: { id: string }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiBundle.isLoading)
  const bundle = useAppSelector((state) => state.apiBundle.bundle)
  const messageBundle = useAppSelector((state) => state.apiBundle.message)
  const messagePicture = useAppSelector((state) => state.apiPicture.message)
  const isLoadingPicture = useAppSelector((state) => state.apiPicture.isLoading)

  const [uploadValue, setUploadValue] = useState(0)
  const [poster, setPoster] = useState<Partial<Picture>>({
    filename: '',
    url: '',
    size: 0,
    mimeType: ''
  })
  const [posterError, setPosterError] = useState<any>(null)
  const dispatch = useAppDispatch()

  const token = currentUser?.token

  const pictureSchema = object({
    thumbnail: object({
      filename: string()
        .required('Thumbnail is required'),
      url: string()
        .required('Thumbnail is required'),
      size: number()
        .required('Thumbnail is required'),
      mimeType: string()
        .nullable()
    }).required('Thumbnail is required')
  })

  const uploadBundlePoster = async (event: any) => {
    try {
      const file = event.target.files[0]
      const fileSizeBytes = 5 * 1024 * 1024

      if (file && file.size > fileSizeBytes) {
        setPosterError('Select an image that is less than 5MB')
        return
      } else {
        setPosterError(null)
      }

      if (file && file.type.startsWith('image/')) {
        const posterFileName = fileNameGenerator(file?.name)

        const storageRefPath = `${firebaseStorageEnvironment}/bundles/images/${posterFileName}`

        const storageRef = refFirebase(storageFirebase, storageRefPath)

        // upload file
        const uploadTask = uploadBytesResumableFirebase(storageRef, file)

        uploadTask.on('state_changed',
          (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadValue(percentage)
          },
          (error) => {
            setPosterError(error.message)
            setUploadValue(0)
          },
          () => {
            getDownloadURLFirebase(uploadTask.snapshot.ref).then((url) => {
              getMetadataFirebase(storageRef).then((metadata) => {
                const poster: Pick<Picture, 'filename' | 'mimeType' | 'size' | 'url'> = {
                  url,
                  filename: metadata.name,
                  size: metadata.size,
                  mimeType: metadata.contentType || file.type
                }
                setPoster(poster)
                setPosterError(null)
                localStorage.setItem('bundlePicture', JSON.stringify(poster))
                const controller = new AbortController()
                const signal = controller.signal

                if (token && id) {
                  dispatch(
                    addPictureToBundle({
                      id,
                      token,
                      picture: poster,
                      signal
                    })
                  )
                }
              })
            })
          }
        )
      }
    } catch (error: any) {
      setPosterError(error.message)
    }
  }

  const fileNameGenerator = (filename: string) => {
    const ext = filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
    return `${Date.now()}-${uuidv4()}.${ext}`
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getBundleById({ id, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [id, messagePicture, messageBundle])

  useEffect(() => {
    if (bundle && bundle.pictures.length > 0) {
      setPoster(bundle.pictures[0])
    } else {
      (document?.getElementById('bundlePicture') as HTMLInputElement).value = ''
      setPoster({
        filename: '',
        url: '',
        size: 0,
        mimeType: ''
      })
    }
    setPosterError(null)
  }, [bundle])

  useEffect(() => {
    if (messageBundle === PICTURE_POSTED_SUCCESS_MESSAGE) {
      setUploadValue(0)
    }
  }, [messageBundle])

  useEffect(() => {
    if (messagePicture === PICTURE_DELETION_MESSAGE) {
      (document?.getElementById('bundlePicture') as HTMLInputElement).value = ''
    }
    if (messagePicture) {
      const payload = {
        title: 'Success',
        message: messagePicture,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetPictureMessage())
    }
  }, [messagePicture])

  return (
    <div>
      <Formik
        validationSchema={pictureSchema}
        enableReinitialize
        initialValues={{ thumbnail: poster }}
        onSubmit={({ thumbnail }, actions) => {
          const controller = new AbortController()
          const signal = controller.signal

          const { filename, mimeType, size, url } = thumbnail
          const picture = { filename, mimeType, size, url }
          if (token && id) {
            dispatch(
              addPictureToBundle({
                id,
                token,
                picture,
                signal
              })
            )
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          resetForm
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="bundlePicture" className="form-label w-100">
                    Thumbnail
                    <input
                      type="file"
                      id="bundlePicture"
                      className={`form-control ${
                        (errors.thumbnail && touched.thumbnail)
                          ? 'is-invalid'
                          : ''
                      }`}
                      onClick={(e) => {
                        if (poster.url) {
                          e.preventDefault()
                        }
                      }}
                      style={{
                        display: 'none'
                      }}
                      disabled={isLoading}
                      onChange={(event) => {
                        if (event?.target?.files) {
                          // setThumbnailUrl(URL.createObjectURL(event!.target!.files[0]))
                          uploadBundlePoster(event)
                        }
                      }}
                      accept="image/*"
                    />
                    <div className="post-thumb mt-2">
                      {(poster.url && !isLoading)
                        ? (
                          <>
                            <button
                              type="button"
                              title="Remove Image"
                              className="btn btn-danger btn-round btn-sm position-absolute top-0 end-0 m-2 z-1"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (token && poster.id) {
                                  const controller = new AbortController()
                                  const signal = controller.signal
                                  dispatch(deletePictureById({ token, pictureId: poster.id, signal }))
                                }
                                setPoster({
                                  filename: '',
                                  url: '',
                                  size: 0,
                                  mimeType: ''
                                })
                              }}
                              disabled={isLoading || isSubmitting || isLoadingPicture}
                            >
                              <TrashIcon />
                            </button>
                            <img className="img-poster text-center" src={poster.url} alt="Thumbnail Preview" />
                          </>
                          )
                        : <div>
                            <img className="img-poster text-center" src={ImagePlaceholder} alt="Thumbnail Preview" />
                            <div style={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                              <span className='fs-6'>Click to browse files</span>
                            </div>
                          </div>
                      }
                    </div>
                    <div className="progress w-100" style={{ height: '0.2em' }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-label="Thumbnail Upload Progress"
                        aria-valuenow={uploadValue}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{
                          width: `${uploadValue}%`
                        }}
                      >
                      </div>
                    </div>
                  </label>

                  <div
                    id="validationBundlePictureFeedback"
                    className="error-text"
                  >
                    {posterError || errors.thumbnail?.url || errors.thumbnail?.filename}
                  </div>
                </div>
              </div>

            </div>
            <div className="text-end">
              <div className="d-flex flex-row float-end" role="group" aria-label="Thumbnail Action Buttons">
                <button
                  type="submit"
                  className="btn btn-primary d-none"
                  disabled={isLoading || isSubmitting || isLoadingPicture}
                >
                  <i className="bi bi-plus-circle"></i> Add
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default PictureUploader
